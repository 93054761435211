
import { defineComponent, ref } from 'vue'
import TmFormLine from '@/components/shared/TmFormLine.vue'
import TmRepeatForm from '@/components/shared/TmRepeatForm.vue'
import { endOptions, repeatOptions } from '@/definitions/_general/_data/frequencyOptions'
import { utcZonesOptions } from '@/definitions/_general/_data/utc'
import { useBreakpoints } from '@/compositions/breakpoints'

export default defineComponent({
  name: 'ComposeScheduleSection',
  components: { TmRepeatForm, TmFormLine },
  setup() {
    const { isSmMax } = useBreakpoints()
    const sendDate = ref(new Date('2022-04-19'))
    const sendHours = ref('13')
    const sendMinutes = ref('00')
    const timezone = ref(utcZonesOptions[16])
    const repeat = ref({
      currentRepeat: repeatOptions[0],
      currentEnd: endOptions[0],
      repeatCount: 1,
      smsSessions: 1,
    })

    return {
      sendDate,
      isSmMax,
      sendHours,
      sendMinutes,
      timezone,
      repeat,
    }
  },
})
